import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { logAction } from '../../_actions';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  TablePagination,
  Paper
} from '@mui/material'
import { crudService } from '../../_services';
import { stringUtils } from '../../utils'
import HistoryIcon from '@mui/icons-material/History';
import { PaginationActions } from 'components'
import moment from 'moment'
import { TIPO_LOG } from '../../utils/constants';

const EditorHeader = ({ title, tabela, chave, props }) => {
  const dispatch = useDispatch()

  const [log, setLog] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);

  const { page, limit, term, order } = useSelector(state => state.log);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setLog([])
    setIsLoading(false)
    setTotalRegistros(0)
  }, [])

  const fetchData = () => {

    if (tabela && chave) {
      let filterModel = {
        Tabela: tabela,
        Chave: chave,
        Page: page || 0,
        Limit: limit || 10,
        Term: term || '',
        Order: order || 'dataHora',
        Direction: 'asc'
      };

      setIsLoading(true)
      let apiEndpoint = 'logs' + stringUtils.serialize(filterModel)
      crudService
        .get(apiEndpoint)
        .then((response) => {
          if (response.data) {
            setLog(response.data.items)
            setTotalRegistros(response.data.totalRows)
            setIsLoading(false)
          }
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
        })
    }
  };

  const handleClickOpen = () => {
    fetchData();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = prop => event => {
    dispatch(logAction.onChangeProps(prop, event))
  };

  const handleChangePage = (_, value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    dispatch(logAction.onChangeProps('page', fakeEvent))
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5">
          <b>{title}</b>
        </Typography>
        <Tooltip title='Exibir Histórico de Modificações'>
          <IconButton
            onClick={handleClickOpen}
            aria-label="logs"
            size="small"
          >
            <HistoryIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>
          <Typography style={{ fontSize: "16px", fontWeight: 'bold', textAlign: "left", marginRight: 5 }}>
            HISTÓRICO DE MODIFICAÇÕES
          </Typography>
          <Typography style={{ fontSize: "12px", textAlign: "left", marginRight: 5, color: '#7C7C7C' }}>
            {'Chave do registro: ' + chave}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table style={{ minWidth: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Data/Hora</TableCell>
                  <TableCell>Usuário</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Campo</TableCell>
                  <TableCell>Valor Antigo</TableCell>
                  <TableCell>Valor Novo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {undefined !== log && log.length
                  ? log.map((n) => {
                    return (
                      <TableRow hover key={n.id}>
                        <TableCell component="th" scope="row">
                          {moment(n.dataHora).format('DD/MM/YYYY HH:mm')}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {n.usuario && n.usuario.trim() != "" ? n.usuario : '-'}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {TIPO_LOG[n.tipo]}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {n.campo ? n.campo : '-'}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {n.valorAntigo ? n.valorAntigo : '-'}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {n.valorNovo ? n.valorNovo : '-'}
                        </TableCell>
                      </TableRow>
                    )
                  })
                  :
                  (
                    isLoading ?
                      Array.apply(null, { length: 6 }).map((e, i) => (
                        <TableRow hover>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                        </TableRow>
                      ))
                      :
                      <TableRow>
                        <TableCell rowSpan={3} colSpan={6} align="center">Nenhum registro encontrado</TableCell>
                      </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalRegistros}
            page={page}
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
            onPageChange={handleChangePage}
            rowsPerPage={limit}
            onRowsPerPageChange={handleChange('limit')}
            ActionsComponent={PaginationActions}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditorHeader
