import React, { useEffect, useState, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { relatorioAction, veiculoAction } from '../../_actions'
import PropTypes from 'prop-types'
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment'
import TitleContentInformation from '../TitleContentInformation'
import HelpIcon from '@mui/icons-material/Help';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import {
  Autocomplete,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Paper,
  Skeleton,
  IconButton,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
  Tooltip
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { MANUAIS_CATEGORIAS } from '../../utils'
import { withRouter } from 'react-router-dom'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import CustomButton from '../../components/CustomButton/CustomButton'
import { HelpModal } from '../../components';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: 8
  }
})

const RelatorioOcorrenciaTelemetriaVeiculo = (props) => {

  const dispatch = useDispatch()
  const isFirstRender = useRef(true);
  const { classes, className, ...rest } = props
  const { relatorio } = props.relatorio
  const { totalRegistros } = props.relatorio
  const { isLoading } = props.relatorio

  const [valueVeiculo, setValueVeiculo] = useState(null);
  const [inputValueVeiculo, setInputValueVeiculo] = useState('');

  const getTodayDate = (): string => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const { veiculo } = useSelector(
    (state) => state.veiculo)

  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState(getTodayDate());

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const [ready, setReady] = React.useState(false);
  const optionsVeiculos = veiculo?.map(u => ({ id: u.value, placa: u.text }));

  let filterModel = {
    DataInicio: dataInicio,
    DataOcorrencia: dataFim,
    Placa: valueVeiculo && valueVeiculo.placa ? valueVeiculo.placa.split('-')[0].trim() : '',
    Page: page,
    Limit: limit
  };

  useEffect(() => {
    dispatch(relatorioAction.clear());
    dispatch(veiculoAction.clear());
  }, []);

  const handleChangeAutoComplete = (obj, array, nameArray) => {
    if (obj != null) {
      let selected = array.filter(
        (item) => item.value == obj.id
      )

      if (nameArray == 'veiculo') {
        dispatch(veiculoAction.changeDetailsVeiculo(selected[0]))
      }
    } else {

    }
  }

  useEffect(() => {
    if (valueVeiculo) {
      handleChangeAutoComplete(valueVeiculo, veiculo, 'veiculo')
    }
  }, [valueVeiculo])

  useEffect(() => {
    if (idOrgao && idOrgao != 0 && idOrgao != '') {
      dispatch(veiculoAction.getVeiculoSelect(idOrgao))
    } else {
      dispatch(veiculoAction.clear());
    }
  }, [idOrgao])

  useEffect(() => {
    ValidatorForm.addValidationRule('isVeiculoSelected', (value) => {
      return valueVeiculo !== null; // Verifica se um valor foi selecionado
    });

    return () => {
      ValidatorForm.removeValidationRule('isVeiculoSelected');
    };
  }, [valueVeiculo]);

  const handlePrint = () => {
    const queryParams = new URLSearchParams(filterModel).toString();
    if (queryParams) {
      window.open(`/relatorioOcorrenciasTelemetriaVeiculoPrint?${queryParams}`, '_blank');
    }
  }

  const handleExportExcel = () => {

    const modifiedFilterModel = {
      ...filterModel,
      Limit: 9999999
    };

    dispatch(relatorioAction.getRelatorioOcorrenciaTelemetriaVeiculoArquivo(modifiedFilterModel));
  }

  const handleSubmit = (event) => {
    dispatch(relatorioAction.getRelatorioOcorrenciaTelemetriaVeiculo(filterModel, true));
  }

  useEffect(() => {
    // se é a primeira renderização, não faz a chamada
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    dispatch(relatorioAction.getRelatorioOcorrenciaTelemetriaVeiculo(filterModel, true));
  }, [page, limit]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {

  }, [totalRegistros, isLoading]);

  useEffect(() => {
  }, [ready, props.relatorio.isLoading])

  return (
    <Page className={classes.root} title="Relatório - Ocorrências de Telemetria por Veículo">
      <AccessControl
        rule={'relatorios.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <Grid
            {...rest}
            container
            spacing={2}
          >
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Card style={{ marginBottom: 10, marginTop: 0 }}>
                <ValidatorForm
                  id="formRelatorio"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <CardContent>
                    <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
                      <TitleContentInformation
                        subTitle={'RELATÓRIO'}
                        title={'Relatório de Ocorrências de Telemetria por Veículo'}
                      />
                      <Grid item lg={1} md={1} xl={1} xs={12}>
                        <IconButton
                          aria-label="Ajuda"
                          component="a"
                          onClick={() =>
                            handleOpenModalHelp()
                          }
                          size="small"
                        >
                          <Tooltip title={'Ajuda'}>
                            <Tooltip>
                              <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                            </Tooltip>
                          </Tooltip>
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={5} md={5} xl={5} xs={12}>
                        <Autocomplete
                          value={valueVeiculo}
                          onChange={(event, newValue) => {
                            setValueVeiculo(newValue);
                          }}
                          inputValue={inputValueVeiculo}
                          onInputChange={(event, newInputValueVeiculo) => {
                            setInputValueVeiculo(newInputValueVeiculo);
                          }}
                          variant="outlined"
                          margin="normal"
                          id="autocomplete-veiculo"
                          options={optionsVeiculos}
                          getOptionLabel={(optionVeiculo) =>
                            optionVeiculo ? optionVeiculo.placa : ''
                          }
                          renderInput={(params) => (
                            <TextValidator
                              {...params}
                              label="Veículo *"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              validators={['isVeiculoSelected']}
                              errorMessages={['Selecione um veículo!']}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={3} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="data"
                          InputLabelProps={{
                            shrink: true
                          }}
                          type="date"
                          label="Data Início"
                          margin="normal"
                          onChange={(e) => setDataInicio(e.target.value)}
                          value={dataInicio || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={3} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="data"
                          validators={['required']}
                          errorMessages={['Informe uma data de fim!']}
                          InputLabelProps={{
                            shrink: true
                          }}
                          type="date"
                          label="Data Fim *"
                          margin="normal"
                          onChange={(e) => setDataFim(e.target.value)}
                          value={dataFim || ''}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ alignItems: 'center', justifyContent: 'left', alignContent: 'left' }}>
                      <Grid item xl={12} lg={12} md={12} xs={12} style={{
                        alignItems: 'bottom',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'right',
                        textAlign: 'right'
                      }}>
                        <Grid>
                          <CustomButton
                            type="submit"
                            variant="contained"
                            dark={true}
                            disabled={props.relatorio.isLoading}
                            startIcon={<SearchIcon />}
                            endIcon={props.relatorio.isLoading && <CircularProgress size={24} />}
                          >
                            {props.relatorio.isLoading ? 'Pesquisando...' : 'Pesquisar'}
                          </CustomButton>
                        </Grid>
                        <Grid>
                          <CustomButton
                            type="submit"
                            onClick={() => handlePrint()}
                            variant="outlined"
                            endIcon={<FontAwesomeIcon icon={faFilePdf} style={{ verticalAlign: 'middle' }} />}
                          >
                            Gerar PDF
                          </CustomButton>
                        </Grid>
                        <Grid>
                          <CustomButton
                            type="button"
                            onClick={() => handleExportExcel()}
                            variant="outlined"
                            endIcon={<FontAwesomeIcon icon={faFileExcel} style={{ verticalAlign: 'middle' }} />}
                          >
                            Exportar para Excel
                          </CustomButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </ValidatorForm>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell><strong>DATA/HORA</strong></TableCell>
                        <TableCell><strong>OCORRÊNCIA</strong></TableCell>
                        <TableCell><strong>CONDUTOR</strong></TableCell>
                        <TableCell><strong>DURAÇÃO</strong></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {relatorio && undefined !== relatorio && relatorio.length
                        ? relatorio.map((n) => {
                          return (
                            <TableRow hover key={n.id}>
                              <TableCell component="th" scope="row">
                                {n.dataOcorrencia ? moment(n.dataOcorrencia).format("DD/MM/yyyy HH:mm:ss") : '-'}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.nome_Ocorrencia ? n.nome_Ocorrencia.toUpperCase() : '-'}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.condutor ? n.condutor.toUpperCase() : '-'}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.tempoSec ? n.tempoSec + ' segundos' : '-'}
                              </TableCell>
                            </TableRow>
                          )
                        })
                        :
                        (isLoading ?
                          Array.apply(null, { length: 6 }).map((e, i) => (
                            <TableRow hover>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                            </TableRow>
                          ))
                          :
                          <TableRow>
                            <TableCell rowSpan={6} colSpan={4} align="center">Nenhum registro encontrado</TableCell>
                          </TableRow>
                        )
                      }
                    </TableBody>
                  </Table>
                  <TablePagination
                    component="div"
                    count={totalRegistros}
                    page={page}
                    onPageChange={handleChangePage}
                    labelRowsPerPage="Linhas por página"
                    labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
                    rowsPerPage={limit}
                    onRowsPerPageChange={handleChangeLimit}
                  />
                </TableContainer>
              </Card>
            </Grid>
            {
              (modalHelp ?
                <HelpModal
                  toggle={toggleModalHelp}
                  modal={modalHelp}
                  props={props}
                  tela={MANUAIS_CATEGORIAS.RELATORIO_OCORRENCIA_TELEMETRIA_VEICULO}
                />
                :
                null
              )
            }
          </Grid>
        )
        }
        no={() => <AccessDenied />}
      />
    </Page >
  )
}
RelatorioOcorrenciaTelemetriaVeiculo.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    relatorio: state.relatorio
  }
}
const connectedRelatorioOcorrenciaTelemetriaVeiculoPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(RelatorioOcorrenciaTelemetriaVeiculo))
)
export { connectedRelatorioOcorrenciaTelemetriaVeiculoPage as RelatorioOcorrenciaTelemetriaVeiculo }
