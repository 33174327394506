import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { veiculoAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  Grid,
  IconButton,
  Input,
  Paper,
  Skeleton,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from '@mui/material'
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined';
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import { history, placaMask, MANUAIS_CATEGORIAS } from '../../utils'
import TitleContent from '../TitleContent'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import { useDebounce } from 'use-debounce';


const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: '13px !important'
  }
})

const Veiculo = (props) => {

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { veiculo } = props.veiculo
  const { totalRegistros } = props.veiculo
  const { isLoading } = props.veiculo

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('placa');
  const [direction] = useState('asc');
  const [ready, setReady] = React.useState(false);

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term.split('-').join('').replace('-', ''),
        Order: order,
        Direction: direction,
        IdOrgao: (idOrgao && idOrgao != '' && idOrgao)
      };

      dispatch(veiculoAction.getVeiculo(filterModel, true));

    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [page, limit, debounceTerm, order, direction, idOrgao]);

  useEffect(() => {

  }, [totalRegistros]);

  useEffect(() => {

  }, [ready, props.veiculo.isLoading])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir este Veículo?',
      description: 'Essa operação é irreversível',
      disabled: props.veiculo.isLoading,
      confirmationText: props.veiculo.isLoading ? 'Excluindo veículo...' : 'Sim, excluir!',
    }).then(() => {
      dispatch(veiculoAction.deleteVeiculoById(id))
    })
  }

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  return (
    <Page className={classes.root} title="Veículos">
      <AccessControl
        rule={'veiculos.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            {undefined !== veiculo && veiculo.length > 0 ? (
              <TitleContent
                rule={'veiculos.create'}
                length={veiculo.length}
                subTitle={'GERÊNCIA DE VEÍCULO'}
                title={'Lista de Veículos'}
                href={'/veiculo'}
                telaManual={MANUAIS_CATEGORIAS.LISTAGEM_VEICULO}
              />
            ) : (
              <TitleContent
                rule={'veiculos.create'}
                length={0}
                subTitle={'GERÊNCIA DE VEÍCULO'}
                title={'Lista de Veículos'}
                href={'/veiculo'}
                telaManual={MANUAIS_CATEGORIAS.LISTAGEM_VEICULO}
              />
            )}
            <Grid container spacing={3}>
              <Grid item xl={3} lg={3} md={3} xs={12}>
                <Input
                  id="term"
                  label="term"
                  placeholder="Filtro"
                  value={term}
                  fullWidth
                  onChange={handleChange('term')}
                />
              </Grid>
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Placa</TableCell>
                    <TableCell>Marca</TableCell>
                    <TableCell>Modelo</TableCell>
                    <TableCell>Órgão</TableCell>
                    <TableCell>Hodômetro</TableCell>
                    <TableCell>Motorista</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== veiculo && veiculo.length
                    ? veiculo.map((n) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><span style={{ lineHeight: '1.5em' }}> {n.placa ? placaMask(n.placa) : ''}</span> {n.telemetria ?
                              <Tooltip title="Possui telemetria">
                                <Tooltip>
                                  <IntegrationInstructionsOutlinedIcon style={{
                                    fontSize: '1.5em',
                                    marginLeft: 1
                                  }} />
                                </Tooltip>
                              </Tooltip> : null}
                              {n.gestaoAbastecimento ?
                                <Tooltip title="Possui gestão de abastecimento">
                                  <Tooltip>
                                    <LocalGasStationOutlinedIcon style={{
                                      fontSize: '1.5em',
                                      marginLeft: 1
                                    }} />
                                  </Tooltip>
                                </Tooltip> : null}
                            </div>
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            {n.marca ? n.marca.nome.toUpperCase() : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            {n.modelo ? n.modelo.toUpperCase() : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            {n.orgao ? n.orgao.nome.toUpperCase() : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            {n.hodometro ? n.hodometro : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            {n.usuariosVeiculos && n.usuariosVeiculos.length == 1 ? n.usuariosVeiculos[0]?.usuario?.nome?.toUpperCase() : ''}
                          </TableCell>
                          <TableCell align="right">
                            <AccessControl
                              rule={'veiculos.edit'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Edit"
                                  component="a"
                                  onClick={() =>
                                    history.push(`/veiculo/${n.id}`)
                                  }
                                  size="small"
                                >
                                  <Tooltip title={'Editar'}>
                                    <Tooltip >
                                      <EditIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                            <AccessControl
                              rule={'veiculos.delete'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() => handleClick(n.id)}
                                  size="small"
                                >
                                  <Tooltip title="Excluir">
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })
                    :
                    (
                      isLoading ?
                        Array.apply(null, { length: 5 }).map((e, i) => (
                          <TableRow hover>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                          </TableRow>
                        ))
                        :
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={7} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeLimit}
            />
          </div>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )
}
Veiculo.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    veiculo: state.veiculo
  }
}
const connectedVeiculoPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(Veiculo))
)
export { connectedVeiculoPage as Veiculo }
