import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { authActions, ordemServicoAction } from '../../_actions'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import PropTypes from 'prop-types'
import moment from 'moment'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { STATUS_SERVICO, placaMask, isWinner, isRefuseByEmpresa } from '../../utils'
import {
  Grid,
  Paper,
  Typography,
  Tooltip
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import { styled } from '@mui/material/styles';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: 8
  }
})

const CustomGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left'
  },
}));

const CustomGridBadge = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const CustomSpan = styled('span')(({ backgroundColor }) => ({
  backgroundColor: backgroundColor || '#000',
  borderRadius: 8,
  padding: 4,
  color: '#fff',
  fontSize: 16,
  marginRight: 4,
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center'
}));

const CustomGrid2 = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: 4
  },
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: -10,
    marginBottom: -8,
    paddingTop: -10,
    paddingBottom: -8,
    paddingRight: 3,
    marginRight: 3,
    paddingLeft: 3,
    marginLeft: 3
  },
}));

const OrdemServicoSummaryCard = (props) => {

  const { match: { params } } = props;
  const { idOrdemServico } = props
  const dispatch = useDispatch()

  const idEmpresa = authActions.isEmpresa() ? props.authentication?.user?.usuariosEmpresas[0]?.idEmpresa : 0

  const ocorrenciaConclusaoOS = props.ordemServico?.ordensServicosOcorrencias?.filter(ocorrencia =>
    ocorrencia.descricao && ocorrencia.descricao?.includes("Ordem de Serviço concluída pelo prestador.")
  )
    .map(ocorrencia => {
      const partes = ocorrencia?.descricao?.split(":");
      return partes.length > 1 ? partes[1].trim() : null; // Pega o texto após ":" e remove espaços extras
    })
    .filter(texto => texto)

  const ocorrenciaConclusaoOSAnexos = props.ordemServico?.ordensServicosOcorrencias?.filter(ocorrencia =>
    ocorrencia.descricao && ocorrencia.descricao.includes("Ordem de Serviço concluída pelo prestador.")
  )
    .flatMap(ocorrencia =>
      ocorrencia.ordensServicosOcorrenciasAnexos?.map(anexo => anexo.caminhoArquivo) || []
    );

  useEffect(() => {
    dispatch(ordemServicoAction.clear())
    if (idOrdemServico) {
      dispatch(ordemServicoAction.getOrdemServicoById(idOrdemServico))
    }

  }, [])

  const empenhos = useSelector((state) => state.ordemServico?.ordensServicosEmpenhos)

  const verifyObraHasEmpenho = () => {
    return empenhos?.some(ts => ts.idOrdemServico == parseInt(idOrdemServico) && ts.excluido != true);
  }

  const returnLinkEmpenhoArchive = () => {
    return empenhos?.find(ts => ts.idOrdemServico == parseInt(idOrdemServico) && ts.excluido != true)?.empenho?.caminhoArquivo;
  }

  return (
    <Grid item lg={12} md={12} xl={12} xs={12}>
      {idOrdemServico &&
        <Paper elevation={3} style={{ marginBottom: 6, backgroundColor: '#f7f7f7', paddingBottom: 4 }}>
          <Grid container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
            {authActions.isEmpresa() &&
              !isRefuseByEmpresa(props.ordemServico, idEmpresa) &&
              ![
                STATUS_SERVICO.PENDENTE,
                STATUS_SERVICO.RECEBENDO_ORCAMENTOS,
                STATUS_SERVICO.LANCAMENTO,
                STATUS_SERVICO.RECUSADO,
              ].includes(props.ordemServico.ultimoEvento) &&
              (
                isWinner(props.ordemServico, idEmpresa) ? (
                  <CustomGridBadge item xl={12} lg={12} md={12} xs={12} align="center">
                    <CustomSpan backgroundColor='#00cc66'>
                      <WarningAmberOutlinedIcon
                        style={{ marginRight: 4, fontSize: 'medium' }}
                      />
                      VOCÊ É A EMPRESA VENCEDORA DESTA ORDEM DE SERVIÇO
                    </CustomSpan>
                  </CustomGridBadge>) : (
                  <CustomGridBadge item xl={12} lg={12} md={12} xs={12} align="center">
                    <CustomSpan backgroundColor='#ef5350'>
                      <WarningAmberOutlinedIcon
                        style={{ marginRight: 4, fontSize: 'medium' }}
                      />
                      VOCÊ NÃO É A EMPRESA VENCEDORA DESTA ORDEM DE SERVIÇO
                    </CustomSpan>
                  </CustomGridBadge>
                )
              )
            }
            {authActions.isEmpresa() &&
              ![
                STATUS_SERVICO.PENDENTE,
                STATUS_SERVICO.LANCAMENTO,
                STATUS_SERVICO.RECUSADO,
              ].includes(props.ordemServico.ultimoEvento) &&
              (
                isRefuseByEmpresa(props.ordemServico, idEmpresa) ? (
                  <CustomGridBadge item xl={12} lg={12} md={12} xs={12} align="center">
                    <CustomSpan backgroundColor='#000'>
                      <WarningAmberOutlinedIcon
                        style={{ marginRight: 4, fontSize: 'medium' }}
                      />
                      SUA EMPRESA DECIDIU NÃO ATENDER ESTA ORDEM DE SERVIÇO
                    </CustomSpan>
                  </CustomGridBadge>
                ) :
                  null
              )
            }
            <CustomGrid item xl={8} lg={8} md={8} xs={12} align="left">
              <CustomTypography variant="overline" style={{ fontSize: 15 }} ><strong>RESUMO DA ORDEM DE SERVIÇO</strong></CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={4} lg={4} md={4} xs={12} align="right">
              <CustomTypography variant="overline"><strong>DATA: </strong>{props.ordemServico.data ? moment(props.ordemServico.data).format("DD/MM/yyyy") : ''}</CustomTypography>
            </CustomGrid>
          </Grid>
          <CustomGrid2 container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
            <CustomGrid item xl={2} lg={2} md={2} xs={12} align="left">
              <CustomTypography variant="overline"><strong>TÍTULO: </strong></CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={9} lg={9} md={9} xs={12} align="left">
              <CustomTypography variant="overline">#{props.ordemServico?.id} {props.ordemServico?.titulo}</CustomTypography>
            </CustomGrid>
          </CustomGrid2>
          <CustomGrid2 container spacing={2} style={{ paddingLeft: 10, paddingRight: 12 }}>
            <CustomGrid item xl={2} lg={2} md={2} xs={12} align="left">
              <CustomTypography variant="overline"><strong>DESCRITIVO: </strong></CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={9} lg={9} md={9} xs={12} align="left">
              <CustomTypography variant="overline">{props.ordemServico?.descritivo}</CustomTypography>
            </CustomGrid>
          </CustomGrid2>
          <CustomGrid2 container spacing={2} style={{ paddingLeft: 10, paddingRight: 12 }}>
            <CustomGrid item xl={2} lg={2} md={2} xs={12} align="left">
              <CustomTypography variant="overline"><strong>VEÍCULO: </strong></CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={9} lg={9} md={9} xs={12} align="left">
              <CustomTypography variant="overline">
                {props.ordemServico?.veiculo?.marca?.nome} / {props.ordemServico?.veiculo?.modelo}&nbsp;&nbsp;
                {props.ordemServico?.veiculo?.ano} - {placaMask(props.ordemServico?.veiculo?.placa)} /&nbsp;
                <span style={{ fontStyle: 'italic', color: '#555' }}>
                  Chassi: {props.ordemServico?.veiculo?.numeroChassis ? props.ordemServico?.veiculo?.numeroChassis : 'Não informado'}
                </span>
              </CustomTypography>
            </CustomGrid>
          </CustomGrid2>
          <CustomGrid2 container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
            <CustomGrid item xl={2} lg={2} md={2} xs={12} align="left">
              <CustomTypography variant="overline"><strong>ENDEREÇO: </strong></CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={9} lg={9} md={9} xs={12} align="left">
              <CustomTypography variant="overline">{props.ordemServico?.endereco ? props.ordemServico?.endereco : 'Não informado'}</CustomTypography>
            </CustomGrid>
          </CustomGrid2>
          <CustomGrid2 container spacing={2} style={{ paddingLeft: 10, paddingRight: 12 }}>
            <CustomGrid item xl={2} lg={2} md={2} xs={12} align="left">
              <CustomTypography variant="overline"><strong>TIPO DE SERVIÇO: </strong></CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={9} lg={9} md={9} xs={12} align="left">
              <CustomTypography variant="overline">{props.ordemServico?.tipoServico?.nome}</CustomTypography>
            </CustomGrid>
          </CustomGrid2>
          <CustomGrid2 container spacing={2} style={{ paddingLeft: 10, paddingRight: 12 }}>
            <CustomGrid item xl={2} lg={2} md={2} xs={12} align="left">
              <CustomTypography variant="overline"><strong>ÓRGÃO: </strong></CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={5} lg={5} md={5} xs={12} align="left">
              <CustomTypography variant="overline">{props.ordemServico.orgao?.nome?.toUpperCase()}</CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={5} lg={5} md={5} xs={12} align="right">
              <CustomTypography variant="overline" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                <strong>Possui empenho:&nbsp;</strong>
                {verifyObraHasEmpenho() ? (
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    SIM
                    {!authActions.isEmpresa() &&
                      <Tooltip title={'Abrir arquivo do empenho em nova aba'}>
                        <OpenInNewOutlinedIcon
                          onClick={() => window.open(returnLinkEmpenhoArchive(), '_blank')}
                          style={{
                            cursor: 'pointer',
                            marginLeft: '5px',
                            fontSize: '1.5em'
                          }}
                        />
                      </Tooltip>
                    }
                  </span>
                ) : (
                  'NÃO'
                )}
              </CustomTypography>
            </CustomGrid>
          </CustomGrid2>
          {ocorrenciaConclusaoOS && ocorrenciaConclusaoOS.length > 0 ?
            <CustomGrid2 container spacing={2} style={{ paddingLeft: 10, paddingRight: 12, alignItems: 'center' }}>
              <CustomGrid item xl={2} lg={2} md={2} xs={12} align="left">
                <CustomTypography variant="overline"><strong>SERVIÇO/DIAGNÓSTICO: </strong></CustomTypography>
              </CustomGrid>
              <CustomGrid item xl={9} lg={9} md={9} xs={12} align="left">
                <CustomTypography
                  variant="overline"
                  style={{
                    display: 'flex', // Torna flexível
                    alignItems: 'center', // Alinha verticalmente
                    flexWrap: 'wrap', // Permite quebra de linha, se necessário
                    gap: '2px', // Espaçamento uniforme entre o texto e os ícones
                  }}
                >
                  {ocorrenciaConclusaoOS[0]?.toUpperCase()}{' '}
                  {ocorrenciaConclusaoOSAnexos && Array.isArray(ocorrenciaConclusaoOSAnexos) && ocorrenciaConclusaoOSAnexos.length > 0 ? (
                    ocorrenciaConclusaoOSAnexos.map((anexo, index) => (
                      <Tooltip title={'Abrir o arquivo em nova aba'}>
                        <Tooltip>
                          <InsertDriveFileOutlinedIcon
                            key={anexo.id || index}
                            onClick={() => window.open(anexo, '_blank')}
                            style={{
                              cursor: 'pointer',
                              fontSize: '1.5em',
                              alignItems: 'center', // Alinha verticalmente
                              marginRight: '4px',
                              marginBottom: 3
                            }}
                          />
                        </Tooltip>
                      </Tooltip>
                    ))
                  ) : (
                    '-'
                  )}
                </CustomTypography>
              </CustomGrid>
            </CustomGrid2>
            : null}
        </Paper>
      }
    </Grid>
  )
}

OrdemServicoSummaryCard.propTypes = {
  classes: PropTypes.object.isRequired,
  idOrdemServico: PropTypes.number.isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number
}

const mapStateToProps = (state) => {
  return state;
};

const connectedOrdemServicoSummaryCard = withRouter(
  connect(mapStateToProps)(withStyles(styles)(OrdemServicoSummaryCard))
);

export default connectedOrdemServicoSummaryCard;
