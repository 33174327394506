const initialState = {
  totalRegistros: 0,
  isLoading: false,
  usuario: [],
  open: false,
  id: '',
  email: '',
  nome: '',
  senha: '',
  sobrenome: '',
  idTipoUsuario: '',
  dataHoraUltimoAcesso: '',
  openModalRequestPasswordReset: false,
  caminhoCnh: '',
  celular: '',
  cnh: '',
  matricula: '',
  telefone: '',
  idUsuarioOrgao: 0,
  plataforma: '',
  versaoSistema: '',
  codigoIntegracaoTelemetria: '',
  emailVerificado: false,
  usuariosDispositivos: []
}

export function usuario(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_USER_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'CREATE_USER_SUCCESS':
      return {
        ...state,
        id: action.user.id,
        isLoading: false,
        email: action.user.email,
        nome: action.user.nome,
        senha: action.user.senha,
        sobrenome: action.user.sobrenome,
        idTipoUsuario: action.user.idTipoUsuario,
        dataHoraUltimoAcesso: action.user.dataHoraUltimoAcesso,
        caminhoCnh: action.user.caminhoCnh,
        celular: action.user.celular,
        cnh: action.user.cnh,
        matricula: action.user.matricula,
        telefone: action.user.telefone,
        plataforma: action.user.plataforma,
        versaoSistema: action.user.versaoSistema,
        idUsuarioOrgao: action.user.idUsuarioOrgao,
        emailVerificado: action.user.emailVerificado,
        codigoIntegracaoTelemetria: action.user.codigoIntegracaoTelemetria,
        usuariosDispositivos: action.user.usuariosDispositivos
      }
    case 'CREATE_USER_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'EDIT_USER_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EDIT_USER_SUCCESS':
      return {
        ...state,
        id: action.user.id,
        isLoading: false,
        email: action.user.email,
        nome: action.user.nome,
        senha: action.user.senha,
        sobrenome: action.user.sobrenome,
        idTipoUsuario: action.user.idTipoUsuario,
        dataHoraUltimoAcesso: action.user.dataHoraUltimoAcesso,
        caminhoCnh: action.user.caminhoCnh,
        celular: action.user.celular,
        cnh: action.user.cnh,
        matricula: action.user.matricula,
        telefone: action.user.telefone,
        plataforma: action.user.plataforma,
        versaoSistema: action.user.versaoSistema,
        idUsuarioOrgao: action.idUsuarioOrgao,
        emailVerificado: action.emailVerificado,
        codigoIntegracaoTelemetria: action.codigoIntegracaoTelemetria,
        usuariosDispositivos: action.usuariosDispositivos
      }
    case 'USUARIOEMAIL_UPDATED':
      return state
    case 'EDIT_USER_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'REQUEST_RESET_PASSWORD_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'REQUEST_RESET_PASSWORD_SUCCESS':
      return {
        ...state,
        isLoading: false,
        openModalRequestPasswordReset: false
      }
    case 'REQUEST_RESET_PASSWORD_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'RESET_PASSWORD_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'RESET_PASSWORD_SUCCESS':
      return {
        ...state,
        isLoading: false
      }
    case 'RESET_PASSWORD_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_ALL_USUARIO':
      return {
        ...state,
        usuario: action.usuario,
        totalRegistros: action.totalRegistros
      }
    case 'USUARIO_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        email: action.email,
        nome: action.nome,
        senha: action.senha,
        sobrenome: action.sobrenome,
        idTipoUsuario: action.idTipoUsuario,
        dataHoraUltimoAcesso: action.dataHoraUltimoAcesso,
        caminhoCnh: action.caminhoCnh,
        celular: action.celular,
        cnh: action.cnh,
        matricula: action.matricula,
        telefone: action.telefone,
        idUsuarioOrgao: action.idUsuarioOrgao,
        plataforma: action.plataforma,
        versaoSistema: action.versaoSistema,
        emailVerificado: action.emailVerificado,
        codigoIntegracaoTelemetria: action.codigoIntegracaoTelemetria,
        usuariosDispositivos: action.usuariosDispositivos
      }
    case 'USER_CLEAR':
      return initialState
    case 'USUARIO_ESTABELECIMENTO_ADD_SUCCESS':
      return {
        ...state,
        usuario: [...state.usuario, action.data.usuario]
      }
    case 'USUARIO_ORGAO_SET_ID':
      return {
        idUsuarioOrgao: action.idUsuarioOrgao
      }
    case 'USUARIO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'OPEN_MODAL_REQUEST_PASSWORD_RESET':
      return {
        ...state,
        openModalRequestPasswordReset: action.open
      }
    case 'USUARIO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'USUARIO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
