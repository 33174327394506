import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { orgaoAction, authActions } from '../../_actions'
import PropTypes from 'prop-types'
import moment from 'moment'
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined';
import {
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Input,
  FormControl,
  FormLabel,
  Paper,
  Skeleton,
  Typography,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TablePagination
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit'
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import { history, MANUAIS_CATEGORIAS } from '../../utils'
import TitleContent from '../TitleContent'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import { useDebounce } from 'use-debounce';

const ButtonFilter = styled(Button)({
  color: '#000',
  border: `1px solid #000`,
  '&:hover': {
    backgroundColor: '#FFC107',
    border: `1px solid #000`,
  },
  "&&:focus": {
    border: `1px solid #000`,
    backgroundColor: '#FFC107',
    transition: 0.3
  }
})

const CustomButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    textAlign: 'center',
    color: '#000'
  },
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  justifyContent: 'right',
  textAlign: 'right',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    textAlign: 'center'
  },
}));

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: '13px !important'
  }
})

const Orgao = (props) => {

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { orgao } = props.orgao
  const { totalRegistros } = props.orgao
  const { isLoading } = props.orgao

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('nome');
  const [direction] = useState('asc');
  const [status, setStatus] = useState('');

  const [ready, setReady] = React.useState(false);

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const buttons = [
    <ButtonFilter size="small" variant="outlined" onClick={() => setStatus('P')}>Pendentes</ButtonFilter>,
    <ButtonFilter size="small" variant="outlined" onClick={() => setStatus('A')}>Aprovados</ButtonFilter>,
    <ButtonFilter size="small" variant="outlined" onClick={() => setStatus('R')}>Recusados</ButtonFilter>,
    <ButtonFilter size="small" variant="outlined" onClick={() => setStatus('')}>Todos</ButtonFilter>
  ];

  useEffect(() => {
    setStatus('')
  }, [])

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdOrgao: (idOrgao && idOrgao != '' && idOrgao),
        Status: status
      };

      dispatch(orgaoAction.getOrgao(filterModel, true));

    };

    fetchData(page, limit, debounceTerm, order, direction);
  }, [page, limit, debounceTerm, order, direction, idOrgao]);

  useEffect(() => {
    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdOrgao: (idOrgao && idOrgao != '' && idOrgao),
        Status: status
      };
      dispatch(orgaoAction.getOrgao(filterModel, true));
    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [status])

  useEffect(() => {

  }, [totalRegistros]);

  useEffect(() => {

  }, [ready, props.orgao.isLoading])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir este órgão?',
      description: <ConfirmationText />,
      disabled: props.orgao.isLoading,
      confirmationText: props.orgao.isLoading ? 'Excluindo órgão...' : 'Sim, excluir!',
    }).then(() => {
      dispatch(orgaoAction.deleteOrgaoById(id))
    })
  }

  function ConfirmationText() {
    return (
      <>
        Essa operação é irreversível. <span style={{ color: '#ff3333' }}>Todos os usuários vinculados a ele serão inativados.</span>
      </>
    );
  }

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  return (
    <Page className={classes.root} title="Órgãos">
      <AccessControl
        rule={'orgaos.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            {undefined !== orgao && orgao.length > 0 ? (
              <TitleContent
                rule={'orgaos.create'}
                length={orgao.length}
                subTitle={'GERÊNCIA DE ÓRGÃO'}
                title={'Lista de Órgãos'}
                telaManual={MANUAIS_CATEGORIAS.LISTAGEM_ORGAO}
                href={'/orgao'}
              />
            ) : (
              <TitleContent
                rule={'orgaos.create'}
                length={0}
                subTitle={'GERÊNCIA DE ÓRGÃO'}
                title={'Lista de Órgãos'}
                telaManual={MANUAIS_CATEGORIAS.LISTAGEM_ORGAO}
                href={'/orgao'}
              />
            )}
            <Grid container spacing={3}>
              <Grid item xl={3} lg={3} md={3} xs={12}>
                <Input
                  id="term"
                  label="term"
                  placeholder="Filtro"
                  value={term}
                  fullWidth
                  onChange={handleChange('term')}
                />
              </Grid>
              {authActions.isGestor() &&
                <Grid item xl={9} lg={9} md={9} xs={12} align="right">
                  <FormControl>
                    <FormLabel>
                      <CustomTypography variant="subtitle1">Filtrar por Status </CustomTypography>
                    </FormLabel>
                    <CustomButtonGroup size="small" variant="outlined">
                      {buttons}
                    </CustomButtonGroup>
                  </FormControl>
                </Grid>
              }
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Último Acesso</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== orgao && orgao.length
                    ? orgao.map((n) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row" className={classes.textField}>                         
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><span style={{ lineHeight: '1.5em' }}>{n.orgao ? n.orgao.nome ? n.orgao.nome.toUpperCase() : '' : ''}</span> {n.orgao && n.orgao.telemetria ?
                              <Tooltip title="Possui telemetria">
                                <Tooltip>
                                  <IntegrationInstructionsOutlinedIcon style={{
                                    fontSize: '1.5em',
                                    marginLeft: 1
                                  }} />
                                </Tooltip>
                              </Tooltip> : null}
                              {n.orgao && n.orgao.gestaoAbastecimento ?
                                <Tooltip title="Possui gestão de abastecimento">
                                  <Tooltip>
                                    <LocalGasStationOutlinedIcon style={{
                                      fontSize: '1.5em',
                                      marginLeft: 1
                                    }} />
                                  </Tooltip>
                                </Tooltip> : null}
                            </div>
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            {n.ultimoAcesso && n.ultimoAcesso != null ? moment(n.ultimoAcesso).format("DD/MM/YYYY HH:mm") : 'NUNCA'}
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            {n.orgao ? (n.orgao.cadastroLiberado == true ? 'CADASTRO LIBERADO' :
                              n.orgao.cadastroLiberado == false ? 'CADASTRO RECUSADO' :
                                n.orgao.cadastroLiberado == null && 'PENDENTE') : ''}
                          </TableCell>
                          <TableCell align="right">
                            <AccessControl
                              rule={'orgaos.edit'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Edit"
                                  component="a"
                                  onClick={() =>
                                    history.push(`/orgao/${n.orgao.id}`)
                                  }
                                  size="small"
                                >
                                  <Tooltip title={'Editar'}>
                                    <Tooltip >
                                      <EditIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                            <AccessControl
                              rule={'orgaos.delete'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() => handleClick(n.orgao.id)}
                                  size="small"
                                >
                                  <Tooltip title="Excluir">
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })
                    :
                    (
                      isLoading ?
                        Array.apply(null, { length: 5 }).map((e, i) => (
                          <TableRow hover>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                          </TableRow>
                        ))
                        :
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={4} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeLimit}
            />
          </div>
        )
        }
        no={() => <AccessDenied />}
      />
    </Page >
  )
}
Orgao.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    orgao: state.orgao
  }
}
const connectedOrgaoPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(Orgao))
)
export { connectedOrgaoPage as Orgao }
