import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import ApprovalOutlinedIcon from '@mui/icons-material/ApprovalOutlined';
import PerfectScrollbar from 'react-perfect-scrollbar'
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined'
import CarCrashOutlinedIcon from '@mui/icons-material/CarCrashOutlined';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Collapse, Divider, Drawer, List, ListItem, Typography } from '@mui/material'
import packageInfo from '../../../../../package.json';
import { withStyles } from '@mui/styles'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import BusinessIcon from '@mui/icons-material/Business';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HomeIcon from '@mui/icons-material/Home';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { authActions } from '../../../../_actions'
import { connect, useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Profile, SidebarNav } from './components'
import { HistoryOutlined } from '@mui/icons-material';

const styles = (theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(1, 0),
    color: '#1C5585'
  }
})

const Sidebar = ({ ...props }) => {

  const { open, variant, onClose, className, ...rest } = props
  const dispatch = useDispatch()
  const { user } = props
  const [openMenuCadastros, setOpenMenuCadastros] = useState(false);
  const [openMenuOrcamentos, setOpenMenuOrcamentos] = useState(false);
  const [openMenuRelatorios, setOpenMenuRelatorios] = useState(false);
  const [isClickedOptionSubmenu, setIsClickedOptionSubMenu] = useState(false);

  const logout = () => {
    dispatch(authActions.logout())
  }

  const { classes } = props

  const isDisabled = useSelector((state) => {
    return false
  })

  const handleClickCadastros = () => {
    setOpenMenuCadastros(!openMenuCadastros);
  };

  const handleClickOrcamentos = () => {
    setOpenMenuOrcamentos(!openMenuOrcamentos);
  };

  const handleClickRelatorios = () => {
    setOpenMenuRelatorios(!openMenuRelatorios);
  };

  const pages = [
    {
      title: 'Home',
      href: '/',
      icon: <HomeIcon />
    }
  ];

  const pagesEmpenhos = [
    {
      title: 'Empenhos',
      href: '/empenhos',
      icon: <LocationCityIcon />,
      rule: 'empenhos.list',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu
    }
  ]

  const pagesRelatorios = [
    {
      disabled: isDisabled,
      title: 'Relatórios',
      clicked: openMenuRelatorios,
      href: '#',
      rule: 'dashboard',
      icon: <SummarizeOutlinedIcon />,
      onClick: () => {
        handleClickRelatorios()
      }
    }
  ]

  const pagesSubRelatorios = [
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      title: 'Empresas por Órgão',
      href: '/relatorioEmpresasPorOrgao',
      rule: 'dashboard',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <ApprovalOutlinedIcon />
    },
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      title: 'Logs do Sistema',
      href: '/relatorioLogsSistema',
      rule: 'ordensServico.alterStatus',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <HistoryOutlined />
    },
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      title: 'Ocorrências de Ordens de Serviço',
      href: '/relatorioOcorrenciasOrdemServico',
      rule: 'dashboard',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <CorporateFareIcon />
    },
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      title: 'Ocorrências de Telemetria por Veículo',
      href: '/relatorioOcorrenciasTelemetriaVeiculo',
      rule: 'dashboard',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <CarCrashOutlinedIcon />
    },
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      title: 'Serviços Realizados',
      href: '/relatorioServicosRealizados',
      rule: 'dashboard',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <ConstructionOutlinedIcon />
    }
  ]

  if (!authActions.isUsuarioOrgaoPublico()) {
    pages.push({
      disabled: isDisabled,
      title: 'Cadastros',
      clicked: openMenuCadastros,
      href: '',
      rule: 'dashboard',
      icon: <AddCircleIcon />,
      onClick: () => {
        handleClickCadastros()
      }
    });
  }


  const pagesOrdemServico = [
    {
      title: 'Ordens de Serviço',
      href: '/ordensServico',
      icon: <AssignmentIcon />,
      rule: 'ordensServico.list',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu
    }
  ]

  const pagesOrdemServicoCobrancas = [
    {
      title: 'Cobranças',
      href: '/ordensServicosCobrancas',
      icon: <CreditCardIcon />,
      rule: 'ordensServico.list',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu
    }
  ]

  const pageAjuda = [
    {
      title: 'Ajuda',
      href: '/manuais',
      //por hora, só está disponível para 3 nívels de usuários
      rule: 'manuais.list',
      icon: <HelpOutlineOutlinedIcon />
    }
  ]

  const pagesSair = [
    {
      title: 'Sair',
      href: '/login',
      icon: <ExitToAppIcon />,
      onClick: () => {
        logout()
      }
    }
  ]

  const pagesCadastrosItens = [
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      title: 'Empresas',
      href: '/empresas',
      rule: 'empresas.list',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <WorkOutlineIcon />
    },
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      title: 'Marcas',
      href: '/marcas',
      rule: 'marcas.list',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <BrandingWatermarkIcon />
    },
    {
      disabled: isDisabled,
      title: 'Notificações',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      href: '/notificacoes',
      rule: 'notificacoes',
      icon: <NotificationsIcon />
    },
    {
      disabled: isDisabled,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      title: 'Órgãos',
      href: '/orgaos',
      rule: 'orgaos.list',
      icon: <BusinessIcon />
    },
    {
      disabled: isDisabled,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      title: 'Parâmetros',
      href: '/parametros',
      rule: 'parametros.list',
      icon: <SettingsIcon />
    },
    {
      disabled: isDisabled,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      title: 'Sub Órgãos',
      href: '/subOrgaos',
      rule: 'subOrgaos.create',
      icon: <BusinessIcon />
    },
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      title: 'Usuários',
      href: '/usuarios',
      rule: 'usuarios.list',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <PeopleIcon />
    },
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      title: 'Veículos',
      href: '/veiculos',
      rule: 'veiculos.list',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <DirectionsCarIcon />
    },
  ]

  const pagesOrcamentos = [
    {
      disabled: isDisabled,
      title: 'Orçamentos',
      clicked: openMenuOrcamentos,
      href: '#',
      rule: 'ordensServicoOrcamentos.list',
      icon: <AttachMoneyIcon />,
      onClick: () => {
        handleClickOrcamentos()
      }
    }
  ]


  const pagesSubOrcamentos = [
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      title: 'Ordens de Serviço Aguardando Orçamentos',
      href: '/ordensServicoAguardandoOrcamento',
      rule: 'ordensServicoOrcamentos.list',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <AccessTimeIcon />
    },
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      title: 'Orçamentos Enviados',
      href: '/ordensServicoOrcamentos',
      rule: 'ordensServicoOrcamentos.list',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <ContentPasteGoIcon />
    },
  ]

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <PerfectScrollbar style={{ height: 'calc(100% - 64px)' }}>
        <div {...rest} className={clsx(classes.root, className)}>
          <Profile user={user} />
          <Divider className={classes.divider} />
          <SidebarNav pages={pages} />
          <div style={{ paddingTop: 0, paddingBottom: 0, marginBottom: 0 }}>
            <Collapse in={openMenuCadastros} timeout="auto">
              <List component="div" disablePadding>
                <ListItem style={{ paddingTop: 0 }}>
                  <SidebarNav pages={pagesCadastrosItens} />
                </ListItem>
              </List>
            </Collapse>
          </div>
          <Divider className={classes.divider} />
          <SidebarNav pages={pagesOrdemServico} />
          {(authActions.isEmpresa() || authActions.isGestor()) &&
            <>
              <Divider className={classes.divider} />
              <SidebarNav pages={pagesOrdemServicoCobrancas} />
            </>
          }
          {(authActions.isPrefeitura() || authActions.isGestor()) &&
            <>
              <Divider className={classes.divider} />
              <SidebarNav pages={pagesEmpenhos} />
            </>
          }
          {(authActions.isEmpresa() || authActions.isGestor()) &&
            <>
              <Divider className={classes.divider} />
              <SidebarNav pages={pagesOrcamentos} />
              <div style={{ paddingTop: 0, paddingBottom: 0, marginBottom: 0 }}>
                <Collapse in={openMenuOrcamentos} timeout="auto">
                  <List component="div" disablePadding>
                    <ListItem style={{ paddingTop: 0, paddingBottom: 0, marginBottom: 0 }}>
                      <SidebarNav pages={pagesSubOrcamentos} style={{ paddingTop: 0, paddingBottom: 0, marginBottom: 0 }} />
                    </ListItem>
                  </List>
                </Collapse>
              </div>
            </>
          }
          <Divider className={classes.divider} />
          {((authActions.isGestor() || authActions.isPrefeitura()) && !authActions.isUsuarioOrgaoPublico()) && //liberado para admin e prefeitura
            <>
              <SidebarNav pages={pagesRelatorios} />
              <div style={{ paddingTop: 0, paddingBottom: 0, marginBottom: 0 }}>
                <Collapse in={openMenuRelatorios} timeout="auto">
                  <List component="div" disablePadding>
                    <ListItem style={{ paddingTop: 0, paddingBottom: 0, marginBottom: 0 }}>
                      <SidebarNav pages={pagesSubRelatorios} style={{ paddingTop: 0, paddingBottom: 0, marginBottom: 0 }} />
                    </ListItem>
                  </List>
                </Collapse>
              </div>
            </>
          }
          <SidebarNav pages={pageAjuda} stye={{ marginBottom: 20 }} />
          <Divider className={classes.divider} />
          <SidebarNav pages={pagesSair} stye={{ marginBottom: 20 }} />
          <Typography variant="subtitle2" align="center" style={{ fontSize: 10, marginTop: 2 }}>
            Versão do sistema: {packageInfo.version}
          </Typography>
        </div>
      </PerfectScrollbar>
    </Drawer>
  )
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user
  }
}
const connectedSidebarPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(Sidebar))
)
export { connectedSidebarPage as Sidebar }
